import {getCountryCodeFromUrl} from '@shared/api/api';
import {lazy} from 'react';

const ROUTES = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Login')),
    name: 'LoginPage',
    isPrivate: false,
    countries: ['MY', 'ID'],
  },
  {
    path: '/register',
    exact: true,
    component: lazy(() => import('../pages/Login')),
    name: 'LoginPage',
    isPrivate: false,
    countries: ['MY'],
  },
  {
    path: '/loan-details/:loanId',
    exact: true,
    component: lazy(() => import('../pages/LoanDetails')),
    name: 'Loan Details',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/my-advance',
    exact: true,
    component: lazy(() => import('../pages/MyLoans')),
    name: 'My Advances',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/Dashboard')),
    name: 'DashboardPage',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/loan-application-process/:loanId?/:status?',
    exact: true,
    component: lazy(() => import('../pages/LoanApplication')),
    name: 'loan-application-process',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/past-payment-history/:loanId',
    exact: true,
    component: lazy(() => import('../pages/Transactions')),
    name: 'Past Payment History',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/repayment/:loanId',
    exact: true,
    component: lazy(() => import('../pages/ManualRepayment/container')),
    name: 'repayment',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/my-profile',
    exact: true,
    component: lazy(() => import('../pages/MyProfile')),
    name: 'my-profile',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/sales-platform/:step',
    exact: true,
    component: lazy(() => import('../pages/SalesPlatform')),
    name: 'sales-platform',
    isPrivate: true,
    countries: ['MY', 'ID'],
  },
  {
    path: '/direct-debit-setup/:status?',
    exact: false,
    component: lazy(() => import('../pages/DirectDebit')),
    name: 'DDSetup',
    isPrivate: false,
    countries: ['MY'],
  },
];

export default ROUTES;

export const useFilteredRoutes = () => {
  const countryCode = getCountryCodeFromUrl();
  return ROUTES.filter((route) => route.countries.includes(countryCode));
};
