import Rightbar from '@pages/Dashboard/components/Rightbar';
import Sidebar from '@pages/Dashboard/components/Sidebar';
import Loader from '@shared/components/Loader';
import {PATH} from '@common/constants';
import {RootState} from '@store';
import {ReactNode, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import NotFound from '@pages/NotFound';

import IdleDetector from '@shared/components/IdleTimer';
import {MainWrapper, PageContainer} from '../App.style';
import {useFilteredRoutes} from './routes';

interface IPublicRouteProps {
  children: ReactNode;
  isAuthenticated: boolean;
  stepToRedirect: number;
}

interface IPrivateRouteProps {
  children: ReactNode;
  isAuthenticated: boolean;
}

function PrivateRoute(props: IPrivateRouteProps) {
  const {children, isAuthenticated} = props;
  if (!isAuthenticated && !window.location.pathname.includes('login')) {
    // if the resume loan notification template then store the its entire url else only store pathName
    if (window.location.href.includes(PATH.RESUME_TEMPLATE_PATH)) {
      sessionStorage.setItem('lastPageUrl', window.location.href);
    } else if (window.location.href.includes('redirect')) {
      sessionStorage.setItem('lastPageUrl', window.location.pathname);
    } else {
      sessionStorage.setItem('lastPageUrl', '');
    }
  }
  return isAuthenticated ? (children as JSX.Element) : <Navigate to='/login' />;
}

function PublicRoute(props: IPublicRouteProps) {
  const {children, isAuthenticated, stepToRedirect} = props;
  if (isAuthenticated) {
    if (stepToRedirect) {
      return <Navigate to={`/sales-platform/${stepToRedirect}`} />;
    }
    return <Navigate to='/' />;
  }
  return children as JSX.Element;
}

function AppRoutes() {
  const {isLoggedIn, stepToRedirect} = useSelector(
    (state: RootState) => state.login
  );

  const filteredRoutes = useFilteredRoutes();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {filteredRoutes.map((route) =>
          route.isPrivate ? (
            <Route
              key={route.name}
              path={route.path}
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  {/* Left side  */}
                  <PageContainer>
                    <IdleDetector />
                    {/* <IdleDetectorPackage /> */}
                    <Sidebar />
                    <MainWrapper>
                      <route.component />
                    </MainWrapper>

                    <Rightbar />
                  </PageContainer>
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={route.name}
              path={route.path}
              element={
                route.exact ? (
                  <PublicRoute
                    isAuthenticated={isLoggedIn}
                    stepToRedirect={stepToRedirect}>
                    <route.component />
                  </PublicRoute>
                ) : (
                  <PageContainer>
                    {/* <IdleDetector /> */}
                    {/* <IdleDetectorPackage /> */}
                    <Sidebar />
                    <MainWrapper>
                      <route.component />
                    </MainWrapper>

                    <Rightbar />
                  </PageContainer>
                )
              }
            />
          )
        )}
        <Route path='*' element={<NotFound showEmpty={false} />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
