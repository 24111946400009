import {MenuItem, Select} from '@mui/material';

interface IDropdown {
  value: any;
  handleChange: (val: any) => void;
  manuList: {value: any; text: string}[];
  placeholderText?: string;
}

function Dropdown({value, handleChange, manuList, placeholderText}: IDropdown) {
  return (
    <Select
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      inputProps={{
        'aria-label': 'Without label',
      }}
      variant='standard'
      sx={{
        width: 'auto',
        border: '1px solid #F2F2F7',
        borderRadius: '24px',
        background: '#FFFFFF',
        paddingLeft: '16px',
        '& .MuiSelect-select': {
          opacity: value ? '1' : '0.5',
        },
        '& .MuiSelect-icon': {
          right: '25px',
          color: '#000000',
        },
        '&:before, &:after': {
          border: 'none',
          background: 'transparent',
        },
        '&.Mui-focused': {
          borderColor: '#f2f2f7',
          backgroundColor: '#ffffff',
        },
        '&:hover': {
          borderColor: '#f2f2f7',
        },
      }}
      displayEmpty
      defaultValue=''>
      {placeholderText ? (
        <MenuItem value='' disabled>
          {placeholderText}
        </MenuItem>
      ) : null}
      {manuList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
}

Dropdown.defaultProps = {
  placeholderText: '',
};

export default Dropdown;
