/* eslint-disable sonarjs/cognitive-complexity */
import {createSlice} from '@reduxjs/toolkit';

interface IBusinessData {
  businessIdentificationNumber: number | string;
  email: string;
  phoneNumber: number | string;
  mobileCode: string;
  firstName: string;
  lastName: string;
  authorizedSignatoryName: string;
  nric: number | string;
}
interface IBankData {
  bankName: string;
  accountType: string;
}

interface IDirectDebitInit {
  businessData: IBusinessData | null;
  bankData: IBankData | null;
  step: number;
  setupError: boolean;
}

const initialState: IDirectDebitInit = {
  businessData: null,
  bankData: null,
  step: 0,
  setupError: false,
};

const directDebit = createSlice({
  name: 'directDebit',
  initialState,
  reducers: {
    setBusinessData(state, actions) {
      state.businessData = actions.payload;
    },
    setBankData(state, actions) {
      state.bankData = actions.payload;
    },
    setStep(state, actions) {
      state.step = actions.payload;
    },
    setSetupError(state, actions) {
      state.setupError = actions.payload;
    },
    resetState() {
      return initialState;
    },
  },
});

const directDebitReducer = directDebit.reducer;

export const directDebitActions = directDebit.actions;

export default directDebitReducer;
